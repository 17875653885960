const equippersProd = {
    search: {
        inputBox: "#search_mini_form input#search",
        queryBtn: "#search_mini_form button[type='submit'].action.search",
        urlParam: "q",
    },
    productClickGrid: {
        productElemWrapper: "#searchspring-content .product.product-item",
        productPidAttr: "data-id",
        immediate: true,
        source: "PRODUCT_CLICK_GRID",
    },
    addToCartFromSRP: {
        addToCartBtnSelector: "#searchspring-content .product.product-item form button[type='submit']",
        source: "ADD_TO_CART_SRP",
    },
       addToCartFromCartPopup: {
        cartItemWrapper: "#mini-cart .product.product-item",
        pidSelector: "#mini-cart .product.product-item input.item-qty.cart-item-qty",
        productPidAttr: "data-cart-item-id",
        qtyPlusSelector: '#mini-cart .product.product-item button.update-cart-item',
        // qtySelector: '#mini-cart .product.product-item input.item-qty.cart-item-qty',
        source: "ADD_TO_CART_FROM_CART_POPUP",
    },
    addToCartFromPDP: {
        addToCartBtnSelector: ".product-add-form #product_addtocart_form button[type='submit']#product-addtocart-button",
        pidSelector: ".product-add-form form#product_addtocart_form",
        productPidAttr: "data-product-sku",
        qtySelector: ".product-add-form form#product_addtocart_form .field.qty .control input.input-text.qty",
        source: "ADD_TO_CART_PDP",
    },
    // addToCartFromCartPage: {
    //     cartItemWrapper: ".cart-container .cart.items.data.table .item-info",
    //     pidSelector: ".cart-container .cart.items.data.table .item-info .control.qty input[data-role='cart-item-qty']",
    //     productPidAttr: "data-cart-item-id",
    //     qtySelector: ".cart-container .cart.items.data.table .item-info .control.qty input[data-role='cart-item-qty']",
    //     source: "ADD_TO_CART_FROM_CART_PAGE"
    // },
    pageView: {
        events: {
            home: {
                uniqueSelectors: {
                    selectors: ["body.cms-home"],
                },
                urlIdentifiers: {
                    urls: ["https://www.equippers.com/"],
                    exactMatch: true,
                },
            },
            search: {
                debounceDelay: 3000,
                uniqueSelectors: {                   
                    selectors: ["body.catalogsearch-result-index #unbxd-product-listing-container"],
            },
            urlIdentifiers: {
                urls: ["/catalogsearch/result/"],
                exactMatch: false,
            },                searchRecall: {
                noOfResultsSelector: "#toolbar-amount .toolbar-amount .ss__search-header__count-total.toolbar-number",
            },
        },
        category: {
            debounceDelay: 9000,
            uniqueSelectors: {
                selectors: ["body.catalog-category-view #unbxd-product-listing-container .ss__content #unbxd-product-results .UNX-grid-block li.product-item"]
            }
        },
  productDisplay: {
      uniqueSelectors: {
          selectors: ["body.catalog-product-view"],
      },
  },
  cart: {
      uniqueSelectors: {
          selectors: ["body.checkout-cart-index "],
      },
      urlIdentifiers: {
          urls: ["/cart"],
          exactMatch: false,
      },
  },
    order: {
                urlIdentifiers: {
                    urls: ["/success"],
                    exactMatch: false,
                },
            },
},
},
}

export default equippersProd;
